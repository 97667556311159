<template>
  <div id="selectMethodPay">
    <div class="pay_msg ffr fw400">
      {{ $t('giftCardView.payment.text1')}}
      <br />
      {{ $t('giftCardView.payment.text2')}}
    </div>
    <div class="container_method">
      <div @click="selectPayment(1)" class="types_pay first">
        <div class="title_type ffr fw500">{{ $t('giftCardView.payment.text3')}}</div>
        <div class="imgs_card">
          <div class="credit_cards">
            <div :style="{'background-image' : 'url(img/VISA.svg)'}" class="img_card one"></div>
            <div :style="{'background-image' : 'url(img/MASTERCARD.svg)'}" class="img_card one"></div>
          </div>
          <div class="credit_cards2">
            <div :style="{'background-image' : 'url(img/DINERS.svg)'}" class="img_card two"></div>
            <div :style="{'background-image' : 'url(img/AMEX.svg)'}" class="img_card two"></div>
          </div>
        </div>
      </div>
      <div
        @click="selectPayment(2)"
        class="types_pay"
        v-if="payMethod=='payU' || payMethod =='wompi' || (payMethod=='mercadoPago' && showPse)"
      >
        <div class="title_type ffr fw500">{{ $t('giftCardView.payment.text4')}}</div>
        <div class="imgs_card pse">
          <div :style="{'background-image' : 'url(img/PSE.png)'}" class="img_card pse"></div>
        </div>
      </div>
      <div class="types_pay" v-if="payMethod=='mercadoPago' && !showPse">
        <div class="title_type ffr fw500">{{ $t('giftCardView.payment.text5')}}</div>
        <div class="imgs_card pse">
          <div :style="{'background-image' : 'url(img/PSE.png)'}" class="img_card pse"></div>
        </div>
      </div>
    </div>
    <div class="cont_back">
      <button @click="backToGiftCard" class="btn_back ffr fw400">
        <i class="icons icon_back"></i>
        &nbsp;&nbsp;&nbsp;{{ $t('giftCardView.payment.text6')}}
      </button>
    </div>
    <div class="pay_terms ffr fw300">
      {{ $t('giftCardView.payment.text7')}}
      <a
        @click="polTerms"
        class="modals_terms"
        href="javascript:void('')"
      ><strong>{{ $t('giftCardView.payment.text8')}}</strong></a>
      y
      <a
        @click="polEmmit"
        class="modals_terms"
        href="javascript:void('')"
      ><strong>{{ $t('giftCardView.payment.text9')}}</strong></a>
      {{ $t('giftCardView.payment.text10')}}.
    </div>
  </div>
</template>
<script>
export default {
  props: ['showPse', 'payMethod'],
  methods: {
    selectPayment (type) {
      this.$emit('payment-type', type);
    },
    polEmmit () {
      this.$emit('open-pol');
    },
    polTerms () {
      this.$emit('open-pol2');
    },
    backToGiftCard () {
      this.$emit('show-card');
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/GiftCard/selectMethodPay.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
