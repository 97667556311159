<template>
  <section ref="styles" style="display:none !important;">
    :root{
    --color: {{ design.color }};
    --imgSelect: url('{{ vendor.imgSelect }}');
    --colorStickPay: {{ design.colorStickPay }};
    --bgColorStickPay: {{ design.bgColorStickPay }};
    --colorDashboard: {{ design.colorDashboard }};
    --colorCheckout: {{ design.colorCheckout }};
    --bgColorCheckout: {{ design.bgColorCheckout }};
    --colorSteper: {{ design.colorSteper }};
    --bgColorStepper: {{ design.bgColorStepper}};
    --borderColor: {{ design.borderColor }};
    --darkColor: {{ design.darkColor }};
    --cardTitleColor: {{ design.cardTitleColor }};
    --cardSubTitleColor: {{ design.cardSubTitleColor }};
    --cardDividerColor: {{ design.cardDividerColor }};
    --cardButtonColor: {{ design.cardButtonColor }};
    --navColor: {{ design.navColor }};
    --footerColor: {{ design.footerColor }};
    --accentColor: {{ design.accentColor }};
    --backgroundColor: {{ design.backgroundColor }};
    --cardBackgroundColor: {{ design.cardBackgroundColor }};
    --navBackgroundColor: {{ design.navBackgroundColor }};
    --btnColorReservationSLider: {{ design.btnColorReservationSLider }};
    --btnBgReservationSLider: {{ design.btnBgReservationSLider }};
    --btnColorReservationFooter: {{ design.btnColorReservationFooter }};
    --btnBgReservationFooter: {{ design.btnBgReservationFooter }};
    --backgroundColorOverlay: {{ design.backgroundColorOverlay }};
    --footerBackgroundColor: {{ design.footerBackgroundColor }};
    --lightColor: {{ design.lightColor }};
    --sidenavColor: {{ design.sidenavColor }};
    --toolbarColor: {{ design.toolbarColor }};
    --calendarColor: {{ design.calendarColor }};
    --typeLogo: {{ design.typeLogo }};
    --backgroundModal: {{ design.backgroundModal }};
    --colorModal: {{ design.colorModal }};
    --color1: {{ design.color1 }};
    --color2: {{ design.color2 }};
    --colorCheck1: {{ design.bgCheckboxTerms }};
    --colorCheck2: {{ design.colorCheckboxTerms }};
    --colorBorderCheck: {{ design.colorBorderCheckboxTerms }};
    --backgroundColorBio: {{ design.backgroundColorBio }};
    --imagePromotion: {{ design.imagePromotion }};
    --bgColorCardEvent: {{ designEvent.bgColorCardEvent }};
    --colorContrastEvent: {{ designEvent.colorContrastEvent }};
    --colorHeadEvent: {{ designEvent.colorHeadEvent }};
    --colorTextEvent: {{ designEvent.colorTextEvent }};
    --colorTitleEvent:{{ designEvent.colorTitleEvent }};
    --backgroundColorInEdit:{{ design.backgroundColorInEdit || '#fff' }};
    --opacityInEdit:{{ design.opacityInEdit ? '0.8' : 'unset' || 'unset' }};
    --backgroundColorPopup:{{ design.backgroundColorPopup }};
    --colorPopup:{{ design.colorPopup }};
    --backgroundCirclePopup:{{ design.circleBackgroundPopup }};
    --colorCirclePopup:{{ design.circleContentColor }};
    --cardBackgroundColorAditional: {{ (design.cardBackgroundColorAditional != null) ? design.cardBackgroundColorAditional : design.cardBackgroundColor }};
    --cardTitleColorAditional: {{ (design.cardTitleColorAditional != null) ? design.cardTitleColorAditional : design.cardTitleColor }};
    }
  </section>
</template>

<script>
export default {
  name: 'custom-vars',
  props: [],
  data () {
    return {};
  },
  mounted: function () {
    let styleTag = document.createElement('style');
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  }
};
</script>
