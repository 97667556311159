<template>
  <div :style="{'background-image' : 'url('+ vendor.imgSelect +')'}" id="GiftCard" v-if="giftCardParams">
    <div class="container_text">
      <div class="header_gift">
        <div :style="{ backgroundImage: 'url(' + giftCardParams.logo + ')' }" class="logo_gift"></div>
      </div>
      <div class="title_gift ffr fw500" v-html="giftCardParams.title"></div>
      <div class="content_gift ffr fw400" v-html="giftCardParams.message"></div>
      <div class="divisor_line"></div>
      <div class="tyc_gift ffr fw400" v-html="giftCardParams.terms"></div>
    </div>
    <div class="container_card">
      <div class="scene scene--card">
        <div class="flipCard">
          <div class="gift_card gift_card_front GiftCard-card card__face card__face--front">
            <div class="header_card">
              <i class="icons icon_gift"></i>
            </div>
            <div class="giftcard_title ffr fw500">{{ $t('giftCardView.title') }}</div>
            <div class="container_data">
              <div class="groups_gift group_one">
                <div class="select_value">
                  <select
                    class="input_gift value_selector ffr fw400"
                    name="valueGift"
                    v-model="valueGift"
                    v-validate="'required'"
                  >
                    <option :value="null" disabled hidden selected>{{ $t('giftCardView.value') }}</option>
                    <option :key="d" :value="data.value" v-for="(data, d) in data">{{ data.label }}</option>
                  </select>
                  <div
                    :class="{'no_error' : !errors.has('valueGift')}"
                    class="text_error ffr fw300"
                  >{{ errors.first("valueGift") }}</div>
                </div>
                <div class="select_date">
                  <v-date-picker
                    :min-date="new Date()"
                    :placeholder="$t('giftCardView.form.shippingDate')"
                    class="date_selector"
                    v-model="dateGift"
                  >
                    <template v-slot="{ inputValue, togglePopover }">
                      <div class="container_date_gift input_gift">
                        <input
                          :placeholder="$t('giftCardView.form.shippingDate2')"
                          :value="inputValue"
                          @click="togglePopover()"
                          class="input_gift input_date ffr fw400"
                          name="dateGift"
                          readonly
                          v-validate="'required'"
                        />
                        <div>
                          <i @click="togglePopover()" class="icons icon_calendar"></i>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                  <div
                    :class="{'no_error' : !errors.has('dateGift')}"
                    class="text_error ffr fw300"
                  >{{ errors.first("dateGift") }}</div>
                </div>
              </div>
              <div class="groups_gift group_two">
                <div class="name_for">
                  <input
                    :placeholder="$t('giftCardView.form.to')"
                    class="input_gift input_left ffr fw400"
                    name="namePerson"
                    type="text"
                    v-model="namePerson"
                    v-validate="'required'"
                  />
                  <div
                    :class="{'no_error' : !errors.has('namePerson')}"
                    class="text_error ffr fw300"
                  >{{ errors.first("namePerson") }}</div>
                </div>
                <div class="email_for">
                  <input
                    :placeholder="$t('giftCardView.form.emailTo')"
                    class="input_gift input_right ffr fw400"
                    name="emailPerson"
                    type="text"
                    v-model="emailPerson"
                    v-validate="'required|email'"
                  />
                  <div
                    :class="{'no_error' : !errors.has('emailPerson')}"
                    class="text_error ffr fw300"
                  >{{ errors.first("emailPerson") }}</div>
                </div>
              </div>
              <div class="groups_gift group_three">
                <div class="name_of">
                  <input
                    :placeholder="$t('giftCardView.form.from')"
                    class="input_gift input_left ffr fw400"
                    name="nameBuyer"
                    type="text"
                    v-model="nameBuyer"
                    v-validate="'required'"
                  />
                  <div
                    :class="{'no_error' : !errors.has('nameBuyer')}"
                    class="text_error ffr fw300"
                  >{{ errors.first("nameBuyer") }}</div>
                </div>
                <div class="email_of">
                  <input
                    :placeholder="$t('giftCardView.form.emailFrom')"
                    class="input_gift input_right ffr fw400"
                    name="emailBuyer"
                    type="text"
                    v-model="emailBuyer"
                    v-validate="'required|email'"
                  />
                  <div
                    :class="{'no_error' : !errors.has('emailBuyer')}"
                    class="text_error ffr fw300"
                  >{{ errors.first("emailBuyer") }}</div>
                </div>
              </div>
              <div class="last_group">
                <textarea
                  :placeholder="$t('giftCardView.form.message')"
                  class="input_gift input_message ffr fw400"
                  name="messageCard"
                  style="min-height: 90px; max-height: 90px"
                  v-model="messageCard"
                  v-validate="'required'"
                ></textarea>
                <div
                  :class="{'no_error' : !errors.has('messageCard')}"
                  class="text_error ffr fw300"
                >{{ errors.first("messageCard") }}</div>
              </div>
              <div class="btn_first_pay">
                <button
                  @click="PayGiftCard"
                  class="btn_pay ffr fw500"
                >{{ $t('giftCardView.form.action') }}</button>
              </div>
            </div>
          </div>
          <div
            class="gift_card gift_card_back GiftCard-card card__face card__face--back"
            v-if="paymentType === 0"
          >
            <div class="header_back">
              <i class="icons icon_card"></i>
            </div>
            <div class="giftcard_title ffr fw500">{{ $t('giftCardView.payment.method') }}</div>
            <selectMethodPayment
              :payMethod="giftCardParams.payMethod"
              :showPse="showPse"
              @open-pol="openPolitice"
              @open-pol2="openPolitice2"
              @payment-type="changePayment"
              @show-card="flipCard"
            ></selectMethodPayment>
          </div>
          <div
            class="gift_card GiftCard-card noFlip"
            v-if="paymentType === 1"
          >
            <paymentCard
              v-if="giftCardParams.payMethod == 'payU'"
              @card-transaction-result="transactionResult"
              @open-pol="openPolitice"
              @open-pol2="openPolitice2"
              @payment-type="changePayment"
              @pendingTransactionCard="pendingTransaction"
            ></paymentCard>
            <paymentCardMP
              v-if="giftCardParams.payMethod == 'mercadoPago'"
              :valueGift="valueGift"
              @card-transaction-result="transactionResult"
              @open-pol="openPolitice"
              @open-pol2="openPolitice2"
              @payment-type="changePayment"
              @pendingTransactionCard="pendingTransaction"
            ></paymentCardMP>
            <CardWompi
              v-if="giftCardParams.payMethod == 'wompi'"
              @card-transaction-result="transactionResult"
              @open-pol="openPolitice"
              @open-pol2="openPolitice2"
              @payment-type="changePayment"
              @pendingTransactionCard="pendingTransaction"
            ></CardWompi>
          </div>
          <!-- FALTA AQUI -->
          <div
            class="gift_card noFlip"
            v-if="paymentType === 2"
          >
            <paymentPse
              v-if="giftCardParams.payMethod == 'payU'"
              @open-pol="openPolitice"
              @open-pol2="openPolitice2"
              @payment-type="changePayment"
              @pse-transaction-result="transactionResult"
            ></paymentPse>
            <paymentPseMP
              v-if="giftCardParams.payMethod == 'mercadoPago'"
              :valueGift="valueGift"
              @open-pol="openPolitice"
              @open-pol2="openPolitice2"
              @payment-type="changePayment"
              @pse-transaction-result="transactionResult"
            ></paymentPseMP>
            <PseWompi
              v-if="giftCardParams.payMethod == 'wompi'"
              @open-pol="openPolitice"
              @open-pol2="openPolitice2"
              @payment-type="changePayment"
              @pse-transaction-result="transactionResult"
            ></PseWompi>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-pays">
      <b-modal :active.sync="showModal">
        <div class="terms-body">
          <div
            class="GiftCard-modalTitle"
            v-if="!declined"
          >{{ $t('giftCardView.confirmation.text1') }}</div>
          <div
            class="GiftCard-modalTitle"
            v-if="declined"
          >{{ $t('giftCardView.confirmation.text2') }}</div>
          <div class="text-modal" v-if="!declined">
            {{($store.state.idVendor === 185) ? 'El bono de regalo ha sido' : 'La tarjeta de regalo ha sido'}} {{ $t('giftCardView.confirmation.text3') }}
            <span
              class="has-text-weight-bold"
            >{{ nameGiftPerson }}</span>
            {{ $t('giftCardView.confirmation.text4') }}
            <span
              class="has-text-weight-bold"
            >{{ emailGiftPerson }}</span>
            {{ $t('giftCardView.confirmation.text5') }}
          </div>
          <div class="has-text-weight-bold text-modal" v-if="declined">{{ transactionFail }}</div>
          <div class="closeButton">
            <button
              @click="modalClose()"
              class="button"
              type="button"
            >{{ $t('giftCardView.confirmation.text6') }}</button>
          </div>
        </div>
      </b-modal>
    </div>
    <div id="modal-terms">
      <b-modal :active.sync="showTerms">
        <div class="terms-body">
          <h3 class="title-modal">{{ $t('giftCardView.confirmation.text7') }}</h3>
          <terms :fecha="'22 de Noviembre del 2019'"></terms>
          <div class="closeButton">
            <button
              @click="openPolitice(); showTerms2 = true"
              class="button"
              type="button"
            >{{ $t('giftCardView.confirmation.text8') }}</button>
          </div>
        </div>
      </b-modal>
    </div>
    <div id="modal-terms2">
      <b-modal :active.sync="showTerms2">
        <div class="terms-body">
          <h3 class="title-modal">{{ $t('checkoutView.text2') }}</h3>
          <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
        </div>
      </b-modal>
    </div>
    <b-loading :active.sync="loader" :can-cancel="false" :is-full-page="loader"></b-loading>
    <CustomVars></CustomVars>
  </div>
</template>
<script>
import CustomVars from '@/components/customVars';
import selectMethodPayment from '@/components/GiftCard/SelectMethodPayGiftCard';
import paymentCard from '@/components/GiftCard/paymentCardGift';
import paymentPse from '@/components/GiftCard/paymentPseGift';
import paymentCardMP from '@/components/GiftCard/paymentCardMercadoPagoGift';
import paymentPseMP from '@/components/GiftCard/paymentPseMercadoPagoGift';
import PseWompi from '../components/GiftCard/PseWompi.vue';
import CardWompi from '../components/GiftCard/CardWompi.vue';
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import globalTypes from '@/store/types/global';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import _ from 'lodash';

export default {
  name: 'GiftCard',
  components: {
    selectMethodPayment,
    paymentCard,
    paymentPse,
    paymentCardMP,
    paymentPseMP,
    terms,
    terms2,
    CustomVars,
    PseWompi,
    CardWompi
  },
  computed: {
    ...mapGetters({
      loader: whiteLabelTypes.getters.loader,
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    })
  },
  data () {
    return {
      giftCardParams: null,
      namePerson: null,
      nameBuyer: null,
      emailBuyer: null,
      emailPerson: null,
      dateGift: null,
      messageCard: null,
      showCard: true,
      paymentType: 0,
      valueGift: null,
      idGiftCard: null,
      dataGift: [],
      declined: false,
      nameGiftPerson: null,
      emailGiftPerson: null,
      transactionFail: null,
      showModal: false,
      data: [],
      showTerms: false,
      showTerms2: false,
      showPse: false
    };
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    this.$store.dispatch({
      type: 'global:getServerTime',
      data: {
        timezone: this.vendor.timezone
      }
    });
    this.nameGiftPerson = window.localStorage.getItem('nameGiftPerson');
    this.emailGiftPerson = window.localStorage.getItem('emailGiftPerson');
    this.$store.dispatch({
      type: 'whiteLabel:banks',
      data: { vendorId: this.$store.getters['whiteLabel:idVendor'] }
    });
    this.$store
      .dispatch({
        type: 'reservation:getGiftCardParams',
        data: { vendorId: this.vendorId }
      })
      .then((response) => {
        if (response.code === 200) {
          this.giftCardParams = response.params[0];
          if (this.giftCardParams == null) {
            window.location.href = '/select';
          }
          let priceList = response.params[0].priceList.split(',');
          let aux = [];
          _.forEach(priceList, function (data) {
            let dataLabel = new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0
            }).format(data);
            aux.push({ value: data, label: dataLabel });
          });
          this.data = aux;
          if (this.giftCardParams.payMethod === 'mercadoPago') {
            this.getStatusMpPSE();
          } else if (this.giftCardParams.payMethod === 'wompi') {
            this.showPse = true;
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    let trans = this.$route.query;
    if (Object.keys(trans).length > 0) {
      if (trans.source_utm) {
        this.pendingTransaction();
      }
      this.showStatusTransaction(trans);
    }
    this.backNone();
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
    this.validateRedirectionWompi();
  },
  methods: {
    editGiftCard () {
      this.flipCard();
    },
    PayGiftCard () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let d = new Date(this.dateGift);
          let date = this.$moment(d).format('YYYY-MM-DD');
          this.dataGift = {
            vendorId: this.vendorId,
            value: this.valueGift,
            date: date,
            nameTo: this.namePerson,
            emailTo: this.emailPerson,
            nameFrom: this.nameBuyer,
            emailFrom: this.emailBuyer,
            comments: this.messageCard,
            idGiftCard: this.idGiftCard
          };
          this.$store
            .dispatch({ type: 'reservation:addGiftCard', data: this.dataGift })
            .then((response) => {
              if (response.code === 200) {
                this.idGiftCard = response.data;
                window.localStorage.setItem('randonGift', response.data);
                window.localStorage.setItem('nameGiftPerson', this.namePerson);
                window.localStorage.setItem(
                  'emailGiftPerson',
                  this.emailPerson
                );
                this.nameGiftPerson = window.localStorage.getItem('nameGiftPerson');
                this.emailGiftPerson = window.localStorage.getItem('emailGiftPerson');
                this.flipCard();
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    changePayment (e) {
      this.paymentType = e;
    },
    transactionResult (msg, status) {
      this.transactionFail = msg;
      this.declined = status;
      this.showModal = !this.showModal;
    },
    backNone () {
      setTimeout(() => {
        const flipped2 = this.$el.querySelector('.gift_card_back');
        if (flipped2.classList.value.split('displayn').length === 1) {
          flipped2.classList.toggle('displayn');
        }
      }, 1000);
    },
    flipCard () {
      let flipCard = this.$el.querySelector('.flipCard');
      flipCard.classList.toggle('is-flipped');
      this.verifyHidden();
    },
    verifyHidden () {
      setTimeout(() => {
        const flipped = this.$el.querySelector('.gift_card_front');
        flipped.classList.toggle('hidden');
        if (flipped.classList.value.split('hidden').length > 1) {
          const flipped2 = this.$el.querySelector('.gift_card_back');
          if (flipped2.classList.value.split('displayn').length > 1) {
            flipped2.classList.toggle('displayn');
          }
        } else {
          const flipped2 = this.$el.querySelector('.gift_card_back');
          if (flipped2.classList.value.split('displayn').length === 1) {
            flipped2.classList.toggle('displayn');
          }
        }
      }, 300);
    },
    modalClose () {
      this.showModal = !this.showModal;
      this.namePerson = null;
      this.nameBuyer = null;
      this.emailBuyer = null;
      this.emailPerson = null;
      this.dateGift = null;
      this.messageCard = null;
      this.showCard = true;
      this.paymentType = 0;
      this.valueGift = null;
      this.idGiftCard = null;
      this.dataGift = [];
      this.declined = false;
      this.nameGiftPerson = null;
      this.emailGiftPerson = null;
      this.transactionFail = null;
      window.location.href = '/giftCard';
    },
    openPolitice () {
      this.showTerms = !this.showTerms;
    },
    openPolitice2 () {
      this.showTerms2 = !this.showTerms2;
    },
    showStatusTransaction (transaction) {
      if (
        parseInt(transaction.polTransactionState) === 4 &&
        parseInt(transaction.polResponseCode) === 1
      ) {
        this.showModal = !this.showModal;
      }
      if (
        parseInt(transaction.polTransactionState) === 6 &&
        (parseInt(transaction.polResponseCode) === 4 ||
          parseInt(transaction.polResponseCode) === 5 ||
          parseInt(transaction.polResponseCode) === 19)
      ) {
        this.transactionFail = 'La transacción fue rechazada';
        this.declined = true;
        this.showModal = !this.showModal;
      }
      if (
        (parseInt(transaction.polTransactionState) === 12 ||
          parseInt(transaction.polTransactionState) === 14) &&
        (parseInt(transaction.polResponseCode) === 9994 ||
          parseInt(transaction.polResponseCode) === 25)
      ) {
        this.declined = true;
        this.transactionFail =
          'La transacción esta pendiente de revisión del pago';
        this.showModal = !this.showModal;
      }
    },
    pendingTransaction () {
      this.declined = true;
      this.transactionFail =
        'La transacción esta pendiente de revisión del pago';
      this.showModal = !this.showModal;
    },
    getStatusMpPSE () {
      this.$http.get('payment/banks/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          let pse = _.find(data.data, { id: 'pse' });
          if (pse) {
            this.showPse = true;
          } else {
            this.showPse = false;
          }
        } else {
          this.showPse = false;
        }
      });
    },
    validateRedirectionWompi () {
      const id = this.$route.query.id;
      if (id && id.includes('-')) {
        let transactionFollowUp = window.localStorage.getItem('transactionWompiFollowUpGiftCard');
        if (!transactionFollowUp) return;
        transactionFollowUp = JSON.parse(transactionFollowUp);
        if (transactionFollowUp.expiresAt < new Date().getTime()) return;
        this.$store.commit(globalTypes.mutations.setVerifyingPayment, true);
        this.longPollingPseWompi(transactionFollowUp.transactionId);
      }
    },
    longPollingPseWompi (transactionId) {
      this.$http.get('/payment/status-transaction?paymentProvider=wompi&transactionId=' + transactionId)
        .then(({ data }) => {
          let message = 'ok';
          let declined = false;
          if (data.status === 'DECLINED' || data.status === 'ERROR') {
            message = data.message;
            declined = true;
          }
          setTimeout(() => {
            this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
            this.transactionResult(message, declined);
            window.localStorage.removeItem('transactionWompiFollowUp');
            this.$router.replace({ query: {} });
          }, 1000);
        }).catch(() => {
          setTimeout(() => {
            this.longPollingTransaction(transactionId);
          }, 700);
        });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/GiftCard/giftcard.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
