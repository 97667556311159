<template>
  <div id="paymentPse">
    <div @click="selectPayment(0)" class="paygift_back ffr fw400">
      <i class="icons icon_back payment"></i>
      &nbsp;&nbsp; {{ $t('giftCardView.payment.text11') }}
    </div>
    <div class="cont_texts_header">
      <span class="title_pse ffr fw500">{{ $t('giftCardView.payment.text37') }}</span>
      <span class="subtitle_pse ffr fw400">
        {{ $t('giftCardView.payment.text15') }}
        <br class="om" />{{ $t('giftCardView.payment.text16') }}
      </span>
    </div>
    <form @submit.prevent="validateBeforePse">
      <div class="groups_pse g1">
        <select
          class="input_pse select_custom full ffr fw400"
          expanded
          id="bank"
          name="bank"
          :placeholder="$t('giftCardView.payment.text38')"
          v-model="pse.bank"
          v-validate="'required'"
        >
          <option :value="null" disabled hidden selected>{{ $t('giftCardView.payment.text38') }}</option>
          <option :key="'bank#' + i" :value="b.value" v-for="(b, i) in bank">{{ b.label }}</option>
        </select>
        <span
          :class="{'no_error' : !errors.has('bank')}"
          class="text_error_card ffr fw300"
        >{{ errors.first("bank") }}</span>
      </div>
      <div class="groups_pse g2_payu">
        <div class="cont_name_payu">
          <input
            class="input_pse half ffr fw400"
            expanded
            id="name"
            name="name"
            :placeholder="$t('giftCardView.payment.text39')"
            type="text"
            v-model="pse.name"
            v-validate="'required|alpha_spaces'"
          />
          <span
            :class="{'no_error' : !errors.has('name')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("name") }}</span>
        </div>
        <div class="cont_email_payu">
          <input
            class="input_pse half ffr fw400"
            id="email"
            name="email"
            :placeholder="$t('giftCardView.payment.text35')"
            type="text"
            v-model="pse.email"
            v-validate="'required|email'"
          />
          <span
            :class="{'no_error' : !errors.has('email')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("email") }}</span>
        </div>
      </div>
      <div class="groups_pse g3">
        <div class="cont_type_doc">
          <select
            class="input_pse full select_custom ffr fw400"
            expanded
            id="typeDoc"
            name="typeDoc"
            :placeholder="$t('giftCardView.payment.text36')"
            v-model="pse.typeDocument"
            v-validate="'required'"
          >
            <option :value="null" disabled hidden selected>{{ $t('giftCardView.payment.text36') }}</option>
            <option
              :key="'bank#' + tp"
              :value="typeP.value"
              v-for="(typeP, tp) in typePerson"
            >{{ typeP.label }}</option>
          </select>
          <span
            :class="{'no_error' : !errors.has('typeDoc')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("typeDoc") }}</span>
        </div>
        <div class="cont_num_doc">
          <input
            class="input_pse number_doc ffr fw400"
            expanded
            id="identification"
            name="identification"
            :placeholder="$t('giftCardView.payment.text19')"
            type="text"
            v-model="pse.identification"
            v-validate="'required|numeric'"
          />
          <span
            :class="{'no_error' : !errors.has('identification')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("identification") }}</span>
        </div>
      </div>
      <div class="groups_pse g4 payu">
        <input
          autocomplete="phone-new"
          class="input_pse full ffr fw400"
          id="phone"
          name="phone"
          :placeholder="$t('giftCardView.payment.text20')"
          type="text"
          v-mask="['##########']"
          v-model="pse.phone"
          v-validate="'required|min:10|max:10'"
        />
        <span
          :class="{'no_error' : !errors.has('phone')}"
          class="text_error_card ffr fw300"
        >{{ errors.first("phone") }}</span>
      </div>
      <div class="groups_pse g4">
        <div class="subgroups_pse">
          <div class="cont_people first">
            <b-switch
              :value="'N'"
              class="ffr fw400"
              name="typePeople"
              true-value="N"
              v-model="pse.typePeople"
              v-validate="'required|included:J,N'"
            >{{ $t('giftCardView.payment.text40') }}</b-switch>
          </div>
          <div class="cont_people">
            <b-switch
              :value="'J'"
              class="ffr fw400"
              name="typePeople"
              true-value="J"
              v-model="pse.typePeople"
              v-validate="'required|included:J,N'"
            >{{ $t('giftCardView.payment.text43') }}</b-switch>
          </div>
        </div>
        <span
          :class="{'no_error' : !errors.has('typePeople')}"
          class="text_error_card ffr fw300"
        >{{ errors.first("typePeople") }}</span>
      </div>
      <div class="cont_pay_pse">
        <div style="position: relative;">
          <notifications
            group="pseNotify"
            position="bottom center"
            style="position: unset!important; width: 95%"
          />
        </div>
        <button class="btn_pay_pse ffr fw500" type="submit">{{ $t('giftCardView.payment.text41') }}</button>
      </div>
      <div class="footer_pse">
        <div class="msg_terms ffr fw300">
          {{ $t('giftCardView.payment.text29') }}
          <a
            @click="polTerms"
            class="ffr fw300"
            href="javascript:void('')"
          >{{ $t('giftCardView.payment.text30') }}</a>
          {{ $t('giftCardView.payment.text31') }}
          <a
            @click="polEmmit"
            class="ffr fw300"
            href="javascript:void('')"
          >{{ $t('giftCardView.payment.text32') }}</a>{{ $t('giftCardView.payment.text33') }}
        </div>
        <div class="mp_image">
          <div class="text_secure_pay ffr fw3000">{{ $t('giftCardView.payment.text42') }}</div>
          <img src="img/payu-color.png" />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data () {
    return {
      bank: [{ value: 0, label: 'Seleccione una entidad bancaria' }],
      typePerson: [
        { value: 'CC', label: 'CC - Cédula de ciudadanía' },
        { value: 'CE', label: 'CE - Cédula de extranjería' },
        { value: 'NIT', label: 'NIT - En caso de ser una empresa' },
        { value: 'TI', label: 'TI - Tarjeta de Identidad' },
        { value: 'PP', label: 'PP - Pasaporte' },
        {
          value: 'CEL',
          label: 'CEL - En caso de identificarse a través de la línea del móvil'
        },
        { value: 'RC', label: 'RC - Registro civil de nacimiento' },
        { value: 'DE', label: 'DE - Documento de identificación extranjero' }
      ],
      pse: {
        bank: null,
        name: null,
        email: null,
        typeDocument: null,
        identification: null,
        phone: null,
        typePeople: null,
        url: 'https://' + this.subDomain() + '.precompro.com/checkout'
      }
    };
  },
  computed: {
    ...mapGetters({
      banks: whiteLabelTypes.getters.banks
    })
  },
  methods: {
    ...mapActions({
      confirmGiftCard: reservationTypes.actions.confirmGiftCard
    }),
    selectPayment (type) {
      this.$emit('payment-type', type);
    },
    validateBeforePse () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.proccessPse(this.card);
        }
      });
    },
    proccessPse () {
      this.$notify({ group: 'pseNotify', clean: true });
      this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
      this.confirmGiftCard({
        idGiftCard: window.localStorage.getItem('randonGift'),
        pse: this.pse,
        payMethod: 'payU'
      })
        .then(response => {
          if (response.data.transactionResponse.state === 'PENDING') {
            window.location.href =
              response.data.transactionResponse.extraParameters.BANK_URL;
          } else {
            this.$emit(
              'pse-transaction-result',
              'Ocurrio un error al procesar la petición con la pasarela de pago',
              true
            );
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          }
        })
        .catch(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          this.$emit(
            'pse-transaction-result',
            'Ocurrio un error al procesar la petición con la pasarela de pago',
            true
          );
        });
    },
    selectBank (selected) {
      this.pse.bank = selected[0].value;
    },
    typePersonSelect (selected) {
      this.pse.typeDocument = selected[0].value;
    },
    polEmmit () {
      this.$emit('open-pol');
    },
    polTerms () {
      this.$emit('open-pol2');
    }
  },
  mounted () {
    this.pse.url = window.location.href;
    let aux = [];
    _.forEach(this.banks, function (data) {
      aux.push({ value: data.pseCode, label: data.description });
    });
    this.bank = aux;
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/GiftCard/paymentPse.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
